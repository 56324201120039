<template>
  <div class="tc-group">
    <div class="tool printbtn">
      <el-button type="success" @click="print">打印或导出PDF文件</el-button>
      <el-button type="success" @click="exportWord">导出Word文件</el-button>
      <br />
      <br />
      <el-button type="success" @click="cutVer" v-if="showVersionsCut">{{
        versionsTitle
      }}</el-button>
      <el-button type="danger" @click="close">关闭</el-button>
      <br />
      <br />
      <!-- 筛选日期 -->
      <div class="setDate" v-if="true">
        <hr />
        <br />
        日期范围 :
        <el-date-picker
          v-model="staDate"
          type="date"
          placeholder="开始日期"
          value-format="timestamp"
        >
        </el-date-picker>
        至
        <el-date-picker
          v-model="endDate"
          type="date"
          placeholder="结束日期"
          value-format="timestamp"
        >
        </el-date-picker>
        <el-button
          icon="el-icon-search"
          circle
          type="success"
          @click="loadData"
        ></el-button>
        <br />
      </div>
      <br />
      <hr />
      <p>使用说明：</p>
      <span class="msg">1、导出DPF文件，请在 “打印机中” 选择 DPF</span>
      <div class="rightClose" @click="closeTool">
        <i :class="closeToolCut==1? 'el-icon-d-arrow-left':'el-icon-d-arrow-right'"></i>
      </div>
    </div>
    <!-- 目前报告分为两个版本 ，  versionsCut  ---- 0:正常版 和 1:简易版 -->
    <!-- ****** 打印、导出pdf****** -->
    <div class="tc-group-layout" v-if="isflag">
      <!-- 正常版本 -->
      <div class="normal" v-if="versionsCut == 0">
        <!-- 首页 -->
        <div class="home-title">
          <p>团 体 心 理 健 康 评 估 报 告</p>
          <p>Psychological Health Management Assessment Report</p>
          <p>{{ infoData.time }}</p>
          <p>{{ config.unit_name }}</p>
        </div>
        <!-- 声明 -->
        <div class="statement">
          <div class="statement-title">
            <p>声明</p>
          </div>
          <div class="statement_content" v-html="infoData.statement"></div>
        </div>
        <!-- 心理体检状况 -->
        <div class="situation">
          <div class="title-1">
            <p>一、心理体检概况</p>
          </div>
          <div class="describe">
            <p>{{ infoData.general }}</p>
          </div>
          <div class="title-2">
            <p>1.1 心理体检人员基本信息</p>
          </div>
          <div class="title-3">
            <p>1.1.1 性别统计分布</p>
          </div>
          <div class="describe">
            <p>{{ infoData.sex_statistics }}（见表 1.1 及图 1.1）。</p>
          </div>
          <!-- 图表1 -->
          <div class="chart">
            <div
              id="chart-report-1"
              :style="{ width: '100%', height: '100%' }"
            ></div>
          </div>
          <!-- 表格1 -->
          <div class="chartTable">
            <table>
              <tr>
                <th></th>
                <th>男</th>
                <th>女</th>
                <th>总数</th>
              </tr>
              <tr>
                <td>人数</td>
                <td>{{ infoData.sex.nan }}</td>
                <td>{{ infoData.sex.nv }}</td>
                <td>{{ infoData.sex.sum }}</td>
              </tr>
              <tr>
                <td>百分百</td>
                <td>{{ infoData.sex.nanPre }}%</td>
                <td>{{ infoData.sex.nvPre }}%</td>
                <td>100%</td>
              </tr>
            </table>
          </div>
          <div class="title-3">
            <p>1.1.2 学历统计分布</p>
          </div>
          <div class="describe">
            <p>表 1.2 及图 1.2 为参加人员年龄状况统计分布情况。</p>
          </div>
          <!-- 图表2 -->
          <div class="chart">
            <div
              id="chart-report-2"
              :style="{ width: '100%', height: '100%' }"
            ></div>
          </div>
          <!-- 表格2 -->
          <div class="chartTable">
            <table>
              <tr>
                <th></th>
                <th>14及以下</th>
                <th>15</th>
                <th>16</th>
                <th>17</th>
                <th>18</th>
                <th>19</th>
                <th>20及以上</th>
              </tr>
              <tr>
                <td>人数</td>
                <td v-for="(item, key) in infoData.age.region" :key="key">
                  {{ item }}
                </td>
              </tr>
              <tr>
                <td>百分百</td>
                <td v-for="(item, key) in infoData.age.Pre" :key="key">
                  {{ item }}%
                </td>
              </tr>
            </table>
          </div>
        </div>
        <!-- 心理健康评估数据分析报告 -->
        <div class="analysis">
          <div class="title-1">
            <p>二、心理健康评估数据分析报告</p>
          </div>
          <div v-if="infoData.measure.result_base">
            <div class="title-2">
              <p>2.1 心理体检人员基本信息</p>
            </div>
            <div class="describe">
              <p v-html="infoData.measure.result_base.content"></p>
            </div>
            <!-- 图表3 -->
            <div class="chart">
              <div
                id="chart-report-3"
                :style="{ width: '100%', height: '100%' }"
              ></div>
            </div>
            <!-- 表格3 -->
            <div class="chartTable">
              <table>
                <tr>
                  <th></th>
                  <th
                    v-for="(item, key) in infoData.measure.result_base.mark"
                    :key="key"
                  >
                    {{ item }}
                  </th>
                </tr>
                <tr>
                  <td>人数</td>
                  <td
                    v-for="(item, key) in infoData.measure.result_base.r"
                    :key="key"
                  >
                    {{ item }}
                  </td>
                </tr>
                <tr>
                  <td>百分百</td>
                  <td
                    v-for="(item, key) in infoData.measure.result_base.rPre"
                    :key="key"
                  >
                    {{ item }}%
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- 2.2 因子健康分布情况 -->
          <div v-if="infoData.measure.yzfx">
            <div class="title-2">
              <p>2.2 参试人员各因子健康状况分布</p>
            </div>
            <div class="describe"></div>
            <!-- 图表4 -->
            <div class="chart">
              <div
                id="chart-report-4"
                :style="{ width: '100%', height: '100%' }"
              ></div>
            </div>
            <!-- 表格4 -->
            <div class="chartTable">
              <table>
                <tr>
                  <th rowspan="2">因子</th>
                  <th
                    colspan="2"
                    v-for="(item, key3) in infoData.measure.degree"
                    :key="key3"
                  >
                    {{ item }}
                  </th>
                </tr>
                <tr v-if="infoData.measure.degree.length > 0">
                  <template v-for="(item, key3) in infoData.measure.degree">
                    <th :key="key3">人 数</th>
                    <th :key="key3">百分百</th>
                  </template>
                </tr>
                <tr v-else>
                  <th style="border-top: 1px solid black">人 数</th>
                  <th style="border-top: 1px solid black">百分百</th>
                </tr>
                <template v-for="(item, key) in infoData.measure.result.name">
                  <tr :key="key" v-if="infoData.measure.degree.length > 0">
                    <td>{{ item }}</td>
                    <template v-for="(item, key2) in infoData.measure.degree">
                      <td :key="key2">
                        {{ infoData.measure.result.r[key2][key] }}
                      </td>
                      <td :key="key2">
                        {{ infoData.measure.result.rPre[key2][key] }}%
                      </td>
                    </template>
                  </tr>
                  <tr :key="key" v-else>
                    <td>{{ item }}</td>
                    <td>{{ infoData.measure.result.r[0][key] }}</td>
                    <td>{{ infoData.measure.result.rPre[0][key] }}%</td>
                  </tr>
                </template>
              </table>
            </div>
            <div class="divisor">
              <div
                class="divisor-item"
                v-for="(item, key) in infoData.measure.yzfx"
                :key="key"
              >
                <p>{{ key + 1 }}、{{ item }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 总结与分析 -->
        <div class="summarize">
          <div class="title-1">
            <p>三、总结与分析</p>
          </div>
          <div class="summarize-conten" v-html="infoData.measure.summary"></div>
        </div>
        <!-- 提示 -->
        <div class="msg">
          <p>
            （本报告根据当前用户测试数据自动得出，仅供参考，不作诊断证明之用）
          </p>
        </div>
        <!-- 签名 -->
        <div class="signature">
          签名：
          <span></span>
          报告时间：
          <span>{{ infoData.time }}</span>
        </div>
      </div>
      <!-- 简易版本 -->
      <div class="simple" v-if="versionsCut == 1">
        <div class="header-title">
          <p class="hosName">{{ config.unit_name }}</p>
          <p class="measName">{{ infoData.title }}</p>
        </div>
        <div class="userInfo">
          <p class="unitName">单位名称：<span></span></p>
          <p class="date">
            测试时间段：<span>{{infoData.begin_end_time.begin_time =="1970-01-01"?'不限':infoData.begin_end_time.begin_time}} - {{infoData.begin_end_time.end_time=="1970-01-01"?'不限':infoData.begin_end_time.end_time}}</span>
          </p>
          <p class="measInofo">{{ infoData.general }}</p>
        </div>
        <div class="statistics">
          <p class="statistics-title">健康体检团体报告</p>
          <p class="statistics-table-name">测评结果统计：</p>
          <div class="statistics-table">
            <table cellspacing="0">
              <tr>
                <th rowspan="2">诊断分类</th>
                <th colspan="3">总数</th>
                <th colspan="3">男</th>
                <th colspan="3">女</th>
              </tr>
              <tr>
                <td>人数</td>
                <td>比率（%）</td>
                <td>平均年龄</td>
                <td>人数</td>
                <td>比率（%）</td>
                <td>平均年龄</td>
                <td>人数</td>
                <td>比率（%）</td>
                <td>平均年龄</td>
              </tr>
              <tr
                v-for="(item, key) in infoData.extra.statisticsUser"
                :key="key"
              >
                <td>{{ item.mark }}</td>
                <td>{{ item.sum }}</td>
                <td>{{ item.pre }}</td>
                <td>{{ item.suMage }}</td>
                <td>{{ item.nan }}</td>
                <td>{{ item.naNpre }}</td>
                <td>{{ item.naNage }}</td>
                <td>{{ item.nv }}</td>
                <td>{{ item.nVpre }}</td>
                <td>{{ item.nVage }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="classify">
          <p class="classify-title">健康体检团体分类报告</p>
          <div class="classify-table">
            <table cellspacing="0">
              <tr>
                <th>心测号</th>
                <th>姓名</th>
                <th>性别</th>
                <th>年龄</th>
                <th>测评结果分类</th>
              </tr>
              <tr v-for="(item, key) in infoData.extra.userBase" :key="key">
                <td>{{ item.account }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.sex }}</td>
                <td>{{ item.age }}</td>
                <td>{{ item.mark }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- ****** 导出 word****** -->
    <div
      class
      style="width: 900px; margin: 0 auto; padding: 40px 60px; overflow: hidden"
      id="downWord"
      v-if="isflag"
      v-show="false"
    >
      <!-- 正常版本 -->
      <div class="normal" v-if="versionsCut == 0">
        <!-- 首页 -->
        <div
          class="home-title"
          style="
            height: 450px;
            box-sizing: border-box;
            border: 5px solid rgb(35, 151, 188);
            text-align: center;
          "
        >
          <p style="font-weight: 700; font-size: 28px; margin-top: 60px">
            团 体 心 理 健 康 评 估 报 告
          </p>
          <p style="font-weight: 700; margin-top: 10px">
            Psychological Health Management Assessment Report
          </p>
          <p style="font-weight: 700; margin-top: 180px">{{ infoData.time }}</p>
          <p style="font-weight: 700; margin-top: 10px">
            {{ config.unit_name }}
          </p>
        </div>
        <!-- 声明 -->
        <div class="statement" style="margin-top: 50px">
          <div class="statement-title" style="margin-top: 10px">
            <p style="text-align: center; font-weight: 700; font-size: 20px">
              声明
            </p>
          </div>
          <div class="statement_content" v-html="infoData.statement"></div>
        </div>
        <!-- 心理体检状况 -->
        <div class="situation">
          <div
            class="title-1"
            style="margin-top: 80px; border-bottom: 2px solid #aaaaaa"
          >
            <p
              style="
                font-size: 20px;
                font-weight: 700;
                color: rgb(0, 118, 174);
                padding: 10px 0;
              "
            >
              一、心理体检概况
            </p>
          </div>
          <div class="describe" style="padding: 10px 0">
            <p>{{ infoData.general }}</p>
          </div>
          <div
            class="title-2"
            style="
              font-size: 18px;
              font-weight: 700;
              color: rgb(25, 113, 83);
              padding-top: 20px;
            "
          >
            <p>1.1 心理体检人员基本信息</p>
          </div>
          <div
            class="title-3"
            style="
              font-size: 16px;
              font-weight: 700;
              color: rgb(255, 106, 64);
              margin-top: 50px;
            "
          >
            <p>1.1.1 性别统计分布</p>
          </div>
          <div class="describe" style="padding: 10px 0">
            <p>
              参加此次心理体检的人员共 562 人，其中男性 295 人，女性 267
              人。年龄区间为 13-22 岁，平均年龄为 17 岁，标准 差为 11.4。（见表
              1.1 及图 1.1）。
            </p>
          </div>
          <!-- 图表1 -->
          <div class="chart" style="margin-top: 10px; height: 350px">
            <!-- <div id="chart-report-1" :style="{width: '100%', height: '100%'}"></div> -->
            <div style="margin: '0 auto '; text-align: center; width: '100%'">
              <img :src="imgUrl[0]" alt />
            </div>
          </div>
          <!-- 表格1 -->
          <div class="chartTable" style="margin-top: 20px">
            <table
              style="
                width: 100%;
                margin: 0px auto;
                border-collapse: collapse;
                border-top: 1px solid black;
                border-left: 1px solid black;
              "
            >
              <tr>
                <th
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                  "
                ></th>
                <th
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                  "
                >
                  男
                </th>
                <th
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                  "
                >
                  女
                </th>
                <th
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                  "
                >
                  总数
                </th>
              </tr>
              <tr>
                <td
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                    text-align: center;
                    font-weight: 700;
                  "
                >
                  人数
                </td>
                <td
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                    text-align: center;
                  "
                >
                  {{ infoData.sex.nan }}
                </td>
                <td
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                    text-align: center;
                  "
                >
                  {{ infoData.sex.nv }}
                </td>
                <td
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                    text-align: center;
                  "
                >
                  {{ infoData.sex.sum }}
                </td>
              </tr>
              <tr>
                <td
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                    text-align: center;
                    font-weight: 700;
                  "
                >
                  百分百
                </td>
                <td
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                    text-align: center;
                  "
                >
                  {{ infoData.sex.nanPre }}%
                </td>
                <td
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                    text-align: center;
                  "
                >
                  {{ infoData.sex.nvPre }}%
                </td>
                <td
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                    text-align: center;
                  "
                >
                  100%
                </td>
              </tr>
            </table>
          </div>
          <div
            class="title-3"
            style="
              font-size: 16px;
              font-weight: 700;
              color: rgb(255, 106, 64);
              margin-top: 50px;
            "
          >
            <p>1.1.2 学历统计分布</p>
          </div>
          <div class="describe" style="padding: 10px 0">
            <p>表 1.2 及图 1.2 为参加人员年龄状况统计分布情况。</p>
          </div>
          <!-- 图表2 -->
          <div class="chart" style="margin-top: 10px; height: 350px">
            <!-- <div id="chart-report-2" :style="{width: '100%', height: '100%'}"></div> -->
            <div style="margin: '0 auto '; text-align: center; width: '100%'">
              <img :src="imgUrl[1]" alt />
            </div>
          </div>
          <!-- 表格2 -->
          <div class="chartTable" style="margin-top: 20px">
            <table
              style="
                width: 100%;
                margin: 0px auto;
                border-collapse: collapse;
                border-top: 1px solid black;
                border-left: 1px solid black;
              "
            >
              <tr>
                <th
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                  "
                ></th>
                <th
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                  "
                >
                  14及以下
                </th>
                <th
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                  "
                >
                  15
                </th>
                <th
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                  "
                >
                  16
                </th>
                <th
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                  "
                >
                  17
                </th>
                <th
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                  "
                >
                  18
                </th>
                <th
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                  "
                >
                  19
                </th>
                <th
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                  "
                >
                  20及以上
                </th>
              </tr>
              <tr>
                <td
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                    text-align: center;
                    font-weight: 700;
                  "
                >
                  人数
                </td>
                <td
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                    text-align: center;
                  "
                  v-for="(item, key) in infoData.age.region"
                  :key="key"
                >
                  {{ item }}
                </td>
              </tr>
              <tr>
                <td
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                    text-align: center;
                    font-weight: 700;
                  "
                >
                  百分百
                </td>
                <td
                  style="
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 5px 0;
                    text-align: center;
                  "
                  v-for="(item, key) in infoData.age.Pre"
                  :key="key"
                >
                  {{ item }}%
                </td>
              </tr>
            </table>
          </div>
        </div>
        <!-- 心理健康评估数据分析报告 -->
        <div class="analysis">
          <div
            class="title-1"
            style="margin-top: 80px; border-bottom: 2px solid #aaaaaa"
          >
            <p
              style="
                font-size: 20px;
                font-weight: 700;
                color: rgb(0, 118, 174);
                padding: 10px 0;
              "
            >
              二、心理健康评估数据分析报告
            </p>
          </div>
          <div v-if="infoData.measure.result_base">
            <div
              class="title-2"
              style="
                font-size: 18px;
                font-weight: 700;
                color: rgb(25, 113, 83);
                padding-top: 20px;
              "
            >
              <p>2.1 心理体检人员基本信息</p>
            </div>
            <div class="describe" style="padding: 10px 0">
              <p v-html="infoData.measure.result_base.content"></p>
            </div>
            <!-- 图表3 -->
            <div class="chart" style="margin-top: 10px; height: 350px">
              <!-- <div id="chart-report-3" :style="{width: '100%', height: '100%'}"></div> -->
              <div style="margin: '0 auto '; text-align: center; width: '100%'">
                <img :src="imgUrl[2]" alt />
              </div>
            </div>
            <!-- 表格3 -->
            <div class="chartTable" style="margin-top: 20px">
              <table
                style="
                  width: 100%;
                  margin: 0px auto;
                  border-collapse: collapse;
                  border-top: 1px solid black;
                  border-left: 1px solid black;
                "
              >
                <tr>
                  <th
                    style="
                      border-right: 1px solid black;
                      border-bottom: 1px solid black;
                      padding: 5px 0;
                    "
                  ></th>
                  <th
                    style="
                      border-right: 1px solid black;
                      border-bottom: 1px solid black;
                      padding: 5px 0;
                    "
                    v-for="(item, key) in infoData.measure.result_base.mark"
                    :key="key"
                  >
                    {{ item }}
                  </th>
                </tr>
                <tr>
                  <td
                    style="
                      border-right: 1px solid black;
                      border-bottom: 1px solid black;
                      padding: 5px 0;
                      text-align: center;
                    "
                  >
                    人数
                  </td>
                  <td
                    style="
                      border-right: 1px solid black;
                      border-bottom: 1px solid black;
                      padding: 5px 0;
                      text-align: center;
                    "
                    v-for="(item, key) in infoData.measure.result_base.r"
                    :key="key"
                  >
                    {{ item }}
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      border-right: 1px solid black;
                      border-bottom: 1px solid black;
                      padding: 5px 0;
                      text-align: center;
                    "
                  >
                    百分百
                  </td>
                  <td
                    style="
                      border-right: 1px solid black;
                      border-bottom: 1px solid black;
                      padding: 5px 0;
                      text-align: center;
                    "
                    v-for="(item, key) in infoData.measure.result_base.rPre"
                    :key="key"
                  >
                    {{ item }}%
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- 2.2 因子健康分布情况 -->
          <div v-if="infoData.measure.yzfx">
            <div
              class="title-2"
              style="
                font-size: 18px;
                font-weight: 700;
                color: rgb(25, 113, 83);
                padding-top: 20px;
              "
            >
              <p>2.2 参试人员各因子健康状况分布</p>
            </div>
            <div class="describe" style="padding: 10px 0"></div>
            <!-- 图表4 -->
            <div class="chart" style="margin-top: 10px; height: 350px">
              <!-- <div id="chart-report-4" :style="{width: '100%', height: '100%'}"></div> -->
              <div style="margin: '0 auto '; text-align: center; width: '100%'">
                <img :src="imgUrl[3]" alt />
              </div>
            </div>
            <!-- 表格4 -->
            <div class="chartTable" style="margin-top: 20px">
              <table
                style="
                  width: 100%;
                  margin: 0px auto;
                  border-collapse: collapse;
                  border-top: 1px solid black;
                  border-left: 1px solid black;
                "
              >
                <tr>
                  <th
                    style="
                      border-right: 1px solid black;
                      border-bottom: 1px solid black;
                      padding: 5px 0;
                    "
                    rowspan="2"
                  >
                    因子
                  </th>
                  <th
                    style="
                      border-right: 1px solid black;
                      border-bottom: 1px solid black;
                      padding: 5px 0;
                    "
                    colspan="2"
                    v-for="(item, key3) in infoData.measure.degree"
                    :key="key3"
                  >
                    {{ item }}
                  </th>
                </tr>
                <tr v-if="infoData.measure.degree.length > 0">
                  <template v-for="(item, key3) in infoData.measure.degree">
                    <th
                      :key="key3"
                      style="
                        border-right: 1px solid black;
                        border-bottom: 1px solid black;
                        padding: 5px 0;
                      "
                    >
                      人 数
                    </th>
                    <th
                      :key="key3"
                      style="
                        border-right: 1px solid black;
                        border-bottom: 1px solid black;
                        padding: 5px 0;
                      "
                    >
                      百分百
                    </th>
                  </template>
                </tr>
                <tr v-else>
                  <th
                    style="
                      border-right: 1px solid black;
                      border-bottom: 1px solid black;
                      padding: 5px 0;
                      border-top: 1px solid black;
                    "
                  >
                    人 数
                  </th>
                  <th
                    style="
                      border-right: 1px solid black;
                      border-bottom: 1px solid black;
                      padding: 5px 0;
                      border-top: 1px solid black;
                    "
                  >
                    百分百
                  </th>
                </tr>
                <template v-for="(item, key) in infoData.measure.result.name">
                  <tr :key="key" v-if="infoData.measure.degree.length > 0">
                    <td>{{ item }}</td>
                    <template v-for="(item, key2) in infoData.measure.degree">
                      <td :key="key2">
                        {{ infoData.measure.result.r[key2][key] }}
                      </td>
                      <td :key="key2">
                        {{ infoData.measure.result.rPre[key2][key] }}%
                      </td>
                    </template>
                  </tr>
                  <tr :key="key" v-else>
                    <td>{{ item }}</td>
                    <td>{{ infoData.measure.result.r[0][key] }}</td>
                    <td>{{ infoData.measure.result.rPre[0][key] }}%</td>
                  </tr>
                </template>
              </table>
            </div>
            <div class="divisor" style="margin-top: 20px">
              <div
                style="padding: 12px"
                class="divisor-item"
                v-for="(item, key) in infoData.measure.yzfx"
                :key="key"
              >
                <p>{{ key + 1 }}、{{ item }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 总结与分析 -->
        <div class="summarize" style="border-bottom: 2px solid #aaaaaa">
          <div
            class="title-1"
            style="margin-top: 80px; border-bottom: 2px solid #aaaaaa"
          >
            <p
              style="
                font-size: 20px;
                font-weight: 700;
                color: rgb(0, 118, 174);
                padding: 10px 0;
              "
            >
              三、总结与分析
            </p>
          </div>
          <div
            class="summarize-conten"
            style="padding: 20px 0"
            v-html="infoData.measure.summary"
          ></div>
        </div>
        <!-- 提示 -->
        <div class="msg" style="padding: 30px 0">
          <p style="font-weight: 700; text-align: center">
            （本报告根据当前用户测试数据自动得出，仅供参考，不作诊断证明之用）
          </p>
        </div>
        <!-- 签名 -->
        <div
          class="signature"
          style="margin-top: 50px; font-size: 18px; text-align: right"
        >
          签名：
          <span>_______________</span>
          报告时间：
          <span>{{ infoData.time }}</span>
        </div>
      </div>
      <!-- 简易版本 -->
      <div class="simple" v-if="versionsCut == 1">
        <div class="header-title" style="text-align: center; font-size: 28px">
          <p class="hosName">{{ config.unit_name }}</p>
          <p class="measName" style="padding-top: 10px; font-weight: 700">
            {{ infoData.title }}
          </p>
        </div>
        <div class="userInfo" style="margin-top: 50px; font-size: 18px">
          <p class="unitName" style="margin-bottom: 35px; font-weight: 700">
            单位名称：<span></span>
          </p>
          <p class="date" style="margin-bottom: 35px">
                     测试时间段：<span>{{infoData.begin_end_time.begin_time =="1970-01-01"?'不限':infoData.begin_end_time.begin_time}} - {{infoData.begin_end_time.end_time=="1970-01-01"?'不限':infoData.begin_end_time.end_time}}</span>
          </p>
          <p class="measInofo" style="margin-bottom: 35px">
            {{ infoData.general }}
          </p>
        </div>
        <div class="statistics" style="margin-top: 70px">
          <p
            class="statistics-title"
            style="text-align: center; font-size: 20px; font-weight: 700"
          >
            健康体检团体报告
          </p>
          <p class="statistics-table-name" style="font-size: 18px">
            测评结果统计：
          </p>
          <div class="statistics-table" style="margin-top: 10px">
            <table
              cellspacing="0"
              style="
                width: 100%;
                border-top: 1px solid black;
                border-left: 1px solid black;
              "
            >
              <tr>
                <th
                  rowspan="2"
                  style="
                    width: 18.18%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  诊断分类
                </th>
                <th
                  colspan="3"
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  总数
                </th>
                <th
                  colspan="3"
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  男
                </th>
                <th
                  colspan="3"
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  女
                </th>
              </tr>
              <tr>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  人数
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  比率（%）
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  平均年龄
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  人数
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  比率（%）
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  平均年龄
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  人数
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  比率（%）
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  平均年龄
                </td>
              </tr>
              <tr
                v-for="(item, key) in infoData.extra.statisticsUser"
                :key="key"
              >
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  {{ item.mark }}
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  {{ item.sum }}
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  {{ item.pre }}
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  {{ item.suMage }}
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  {{ item.nan }}
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  {{ item.naNpre }}
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  {{ item.naNage }}
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  {{ item.nv }}
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  {{ item.nVpre }}
                </td>
                <td
                  style="
                    width: 7.04%;
                    text-align: center;
                    padding: 7px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  {{ item.nVage }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="classify" style="margin-top: 70px">
          <p
            class="classify-title"
            style="
              text-align: center;
              font-size: 20px;
              font-weight: 700;
              margin-bottom: 20px;
            "
          >
            健康体检团体分类报告
          </p>
          <div class="classify-table" style="margin-top: 10px">
            <table
              cellspacing="0"
              style="
                width: 100%;
                border-top: 1px solid black;
                border-left: 1px solid black;
              "
            >
              <tr>
                <th
                  style="
                    width: 12.5%;
                    text-align: center;
                    padding: 10px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  心测号
                </th>
                <th
                  style="
                    width: 12.5%;
                    text-align: center;
                    padding: 10px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  姓名
                </th>
                <th
                  style="
                    width: 12.5%;
                    text-align: center;
                    padding: 10px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  性别
                </th>
                <th
                  style="
                    width: 12.5%;
                    text-align: center;
                    padding: 10px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  年龄
                </th>
                <th
                  style="
                    width: 50%;
                    text-align: center;
                    padding: 10px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  测评结果分类
                </th>
              </tr>
              <tr v-for="(item, key) in infoData.extra.userBase" :key="key">
                <td
                  style="
                    width: 12.5%;
                    text-align: center;
                    padding: 10px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  {{ item.account }}
                </td>
                <td
                  style="
                    width: 12.5%;
                    text-align: center;
                    padding: 10px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  {{ item.name }}
                </td>
                <td
                  style="
                    width: 12.5%;
                    text-align: center;
                    padding: 10px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  {{ item.sex }}
                </td>
                <td
                  style="
                    width: 12.5%;
                    text-align: center;
                    padding: 10px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  {{ item.age }}
                </td>
                <td
                  style="
                    width: 12.5%;
                    text-align: center;
                    padding: 10px 0;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  {{ item.mark }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { groupStatistics } from "@/api/report.js";
import { max } from "moment";
import $ from "jquery";
import saveAs from "file-saver";
import "../../../../../public/static/jquery.wordexport";
export default {
  data() {
    return {
      isflag: false,
      infoData: {},
      sexData: {},
      imgUrl: [],
      config: {},
      versionsCut: 0, //版本切換
      showVersionsCut: false,
      versionsTitle: "简易报告",
      staDate: "",
      endDate: "",
      closeToolCut:1
    };
  },
  created() {
    this.loadData();
    // 获取配置
    this.config = JSON.parse(localStorage.getItem("config"));
  },
  mounted() {},
  methods: {
    // 图表渲染
    chart() {
      //  1、参试人员性别统计分布表
      let chat = this.$echarts;
      let chart1 = this.$echarts.init(
        document.getElementById("chart-report-1")
      );
      let colorArr = [
        {
          top: "#FF9A22", //黄色
          bottom: "#FFD56E",
        },
        {
          top: "#2750FF", //蓝色
          bottom: "#6AB4FF",
        },
      ];
      chart1.setOption({
        animation: false,
        backgroundColor: "#fff",
        title: {
          text: "表 1.1 - 参试人员性别统计分布表",
          textStyle: {
            align: "center",
          },
          left: "center",
        },
        grid: {
          top: "15%",
          right: "10%",
          left: "10%",
          bottom: "12%",
        },
        xAxis: [
          {
            type: "category",
            color: "#333",
            data: this.sexData.name,
            axisLabel: {
              margin: 20,
              color: "#333",
              textStyle: {
                fontSize: 18,
              },
            },
            axisLine: {
              lineStyle: {
                color: "rgba(107,107,107,0.8)",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            min: 0,
            max: this.sexData.max,
            axisLabel: {
              color: "#999",
              textStyle: {
                fontSize: 18,
              },
            },
            axisLine: {
              lineStyle: {
                color: "rgba(107,107,107,0.8)",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                color: "rgba(131,101,101,0.8)",
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: this.sexData.val,
            barWidth: "100px",
            itemStyle: {
              normal: {
                color: function (params) {
                  let num = colorArr.length;
                  return new chat.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: colorArr[params.dataIndex % num].top, // 0% 处的颜色
                      },
                      {
                        //可根据具体情况决定哪根柱子显示哪种颜色
                        offset: 1,
                        color: colorArr[params.dataIndex % num].bottom, // 100% 处的颜色
                      },
                    ],
                    false
                  );
                },
                barBorderRadius: [30, 30, 0, 0],
              },
            },
            label: {
              normal: {
                show: true,
                fontSize: 18,
                fontWeight: "bold",
                formatter: "{c}人",
                color: "#333",
                position: "top",
              },
            },
          },
        ],
      });
      //  2、参试人员年龄统计分布表
      let chart2 = this.$echarts.init(
        document.getElementById("chart-report-2")
      );
      let colorArr2 = [
        {
          top: "#FF9A22", //黄色
          bottom: "#FFD56E",
        },
        {
          top: "#2750FF", //蓝色
          bottom: "#6AB4FF",
        },
        {
          top: "#FF6666", //红
          bottom: "#FFCCCC",
        },
        {
          top: "#99CC99", //
          bottom: "#CCFF99",
        },
        {
          top: "#99CCCC", //
          bottom: "#CCFFFF",
        },
        {
          top: "#FFFF00", //
          bottom: "#FFFF66",
        },
      ];
      chart2.setOption({
        animation: false,
        backgroundColor: "#fff",
        title: {
          text: "表 1.2 - 参试人员年龄统计分布表",
          textStyle: {
            align: "center",
          },
          left: "center",
        },
        grid: {
          top: "15%",
          right: "10%",
          left: "10%",
          bottom: "12%",
        },
        xAxis: [
          {
            type: "category",
            color: "#333",
            data: ["14及以下", "15", "16", "17", "18", "19", "20及以上"],
            axisLabel: {
              margin: 20,
              color: "#333",
              textStyle: {
                fontSize: 18,
              },
            },
            axisLine: {
              lineStyle: {
                color: "rgba(107,107,107,0.8)",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            min: 0,
            max: this.sexData.max,
            axisLabel: {
              color: "#999",
              textStyle: {
                fontSize: 18,
              },
            },
            axisLine: {
              lineStyle: {
                color: "rgba(107,107,107,0.8)",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                color: "rgba(131,101,101,0.8)",
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: this.infoData.age.region,
            barWidth: "50px",
            itemStyle: {
              normal: {
                color: function (params) {
                  let num = colorArr2.length;
                  return new chat.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: colorArr2[params.dataIndex % num].top, // 0% 处的颜色
                      },
                      {
                        //可根据具体情况决定哪根柱子显示哪种颜色
                        offset: 1,
                        color: colorArr2[params.dataIndex % num].bottom, // 100% 处的颜色
                      },
                    ],
                    false
                  );
                },
                barBorderRadius: [30, 30, 0, 0],
              },
            },
            label: {
              normal: {
                show: true,
                fontSize: 18,
                fontWeight: "bold",
                formatter: "{c}人",
                color: "#333",
                position: "top",
              },
            },
          },
        ],
      });

      //  图片转BA64
      let imgURL1 = chart1.getDataURL({
        pixelRatio: 1,
      });
      this.imgUrl.push(imgURL1);
      let imgURL2 = chart2.getDataURL({
        pixelRatio: 1,
      });
      this.imgUrl.push(imgURL2);
      // 4、参试人员各因子健康状况分布表 --个别表没有
      if (this.infoData.measure.yzfx) {
        // 数据配置处理
        let chartSeries = [];
        let xData = this.infoData.measure.result.name;
        let legendShow = true;
        let legendData = this.infoData.measure.degree;
        let colorArr = ["#009933", "#ffff20", "#ff8000", "#ff2020"];
        if (this.infoData.measure.degree.length == 0) {
          legendShow = false;
          legendData = ["低", "中", "高"];
        }
        legendData.forEach((v, k) => {
          let tmp = {
            name: v,
            type: "bar",
            barWidth: "32",
            stack: "总量",
            data: this.infoData.measure.result.r[k],
            itemStyle: {
              normal: {
                color: colorArr[k],
              },
            },
          };

          chartSeries.push(tmp);
        });
        console.log(chartSeries);
        let chart4 = this.$echarts.init(
          document.getElementById("chart-report-4")
        );
        chart4.setOption({
          animation: false,
          backgroundColor: "#fff",
          color: ["#3398DB"],
          title: {
            text: "表 3 - 参试人员各因子健康状况分布表",
            textStyle: {
              align: "center",
            },
            left: "center",
          },
          grid: {
            left: "3%",
            right: "4%",
            top: "10%",
            bottom: "10%",
            containLabel: true,
          },
          legend: {
            data: legendData,
            itemGap: 25,
            left: "center",
            bottom: 0,
            show: legendShow,
          },
          xAxis: [
            {
              type: "category",
              data: xData,
              axisPointer: {
                type: "shadow",
              },
              axisTick: {
                show: false,
                alignWithLabel: true,
              },
              axisLine: {
                show: false,
              },
              axisLabel: {
                rotate: 30,
                textStyle: {
                  fontSize: 14,
                  color: "black",
                  fontFamily: "SourceHanSansCN-Regular",
                },
              },
            },
          ],
          yAxis: [
            {
              max: this.sexData.max + Math.ceil(this.sexData.max / 5),
              type: "value",
              axisLabel: {
                textStyle: {
                  fontSize: 14,
                  color: "#666666",
                  fontFamily: "SourceHanSansCN-Regular",
                },
              },
              yAxisIndex: 0,
              nameTextStyle: {
                color: "#8F96A5",
                fontSize: 16,
                padding: 20,
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              splitLine: {
                lineStyle: {
                  color: "#666666",
                },
              },
            },
          ],
          series: chartSeries,
        });
        let imgURL4 = chart4.getDataURL({
          pixelRatio: 1,
        });
        this.imgUrl.push(imgURL4);
      }
      if (this.infoData.measure.result_base) {
        // 3、参试人员心理健康状况分布表
        let chart3 = this.$echarts.init(
          document.getElementById("chart-report-3")
        );
        let colorArr3 = [
          {
            top: "#339933", //黄色
            bottom: "#339933",
          },
          {
            top: "#FFFF00", //蓝色
            bottom: "#FFFF00",
          },
          {
            top: "#FF0033", //红
            bottom: "#FF0033",
          },
        ];
        chart3.setOption({
          animation: false,
          backgroundColor: "#fff",
          title: {
            text: "表 2 - 参试人员心理健康状况分布表",
            textStyle: {
              align: "center",
            },
            left: "center",
          },
          grid: {
            top: "15%",
            right: "10%",
            left: "10%",
            bottom: "12%",
          },
          xAxis: [
            {
              type: "category",
              color: "#333",
              data: this.infoData.measure.result_base.mark,
              axisLabel: {
                margin: 20,
                color: "#333",
                textStyle: {
                  fontSize: 15,
                },
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(107,107,107,0.8)",
                },
              },
              axisTick: {
                show: false,
              },
            },
          ],
          yAxis: [
            {
              min: 0,
              max: this.sexData.max,
              axisLabel: {
                color: "#999",
                textStyle: {
                  fontSize: 18,
                },
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(107,107,107,0.8)",
                },
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                lineStyle: {
                  color: "rgba(131,101,101,0.8)",
                  type: "dashed",
                },
              },
            },
          ],
          series: [
            {
              type: "bar",
              data: this.infoData.measure.result_base.r,
              barWidth: "120px",
              itemStyle: {
                normal: {
                  color: function (params) {
                    let num = colorArr3.length;
                    return new chat.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: colorArr3[params.dataIndex % num].top, // 0% 处的颜色
                        },
                        {
                          //可根据具体情况决定哪根柱子显示哪种颜色
                          offset: 1,
                          color: colorArr3[params.dataIndex % num].bottom, // 100% 处的颜色
                        },
                      ],
                      false
                    );
                  },
                  barBorderRadius: [0, 0, 0, 0],
                },
              },
              label: {
                normal: {
                  show: true,
                  fontSize: 18,
                  fontWeight: "bold",
                  formatter: "{c}人",
                  color: "#333",
                  position: "top",
                },
              },
            },
          ],
        });
        let imgURL3 = chart3.getDataURL({
          pixelRatio: 1,
        });
        this.imgUrl.push(imgURL3);
      }
    },
    // 加载数据
    loadData() {
      let queryData = this.$route.query;
      queryData.begin_time = this.staDate / 1000;
      queryData.end_time = this.endDate / 1000;
      groupStatistics(queryData).then((res) => {
        if (res.code == 400200) {
          this.infoData = res.data;
          // 数据兼容处理
          // 1、性别统计分布图表
          this.sexData = {
            name: ["男", "女"],
            val: [res.data.sex.nan * 1, res.data.sex.nv * 1],
          };
          this.sexData.max = res.data.age.sum;
          //2、
          //   ** 图表渲染***;
          this.isflag = true;
          // 3、是否存在简易版的数据
          if (res.data.extra) {
            this.showVersionsCut = true;
          } else {
            this.showVersionsCut = false;
          }
          console.log(this.infoData.extra);
          this.$nextTick(() => {
            // 正常版本才对图表进行渲染
            if (this.versionsCut == 0) {
              this.chart();
            }
          });
        }
      });
    },
    // 打印
    print() {
      window.print();
    },
    // 导出word
    exportWord() {
      $("#downWord").wordExport(`${this.infoData.title}-团体报告`);
    },
    // 关闭
    close() {
      window.close();
    },
    // 版本切換
    cutVer() {
      if (this.versionsCut == 0) {
        this.versionsCut = 1;
        this.versionsTitle = "常规报告";
      } else {
        this.versionsCut = 0;
        this.versionsTitle = "简易报告";
        this.$nextTick(() => {
          this.chart();
        });
      }
    },
    //
    closeTool() {
      if(this.closeToolCut == 1){
       document.querySelector(".tool").style.left = -400 + "px";
       this.closeToolCut = 0
      }else{
        document.querySelector(".tool").style.left = 20 + "px";
       this.closeToolCut = 1
      }
      
    },
  },
};
</script>
<style lang="less">
@media print {
  .printbtn {
    display: none;
  }
  .PageNext {
    page-break-after: always;
  }
  .seal {
    page-break-inside: avoid;
  }
}
.tc-group {
  padding: 10px;
  .tool {
    position: fixed;
    top: 20px;
    left: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    padding: 10px;
    transition: all 0.3s;
    p {
      padding: 8px 0;
      font-weight: 700;
    }
    .msg {
      color: red;
    }
    .setDate {
      .el-date-editor {
        width: 140px;
      }
      .el-button {
        margin-left: 10px;
      }
    }
    .rightClose {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      .el-icon-d-arrow-left,.el-icon-d-arrow-right {
        font-size: 30px;
      }
    }
  }
  .tc-group-layout {
     width: 900px;
    margin: 0 auto;
    padding: 40px 60px;
    overflow: hidden;
    // 正常
    .normal {
      .title-1 {
        margin-top: 80px;
        border-bottom: 2px solid #aaaaaa;
        p {
          font-size: 20px;
          font-weight: 700;
          color: rgb(0, 118, 174);
          padding: 10px 0;
        }
      }
      .title-2 {
        font-size: 18px;
        font-weight: 700;
        color: rgb(25, 113, 83);
        padding-top: 20px;
      }
      .title-3 {
        font-size: 16px;
        font-weight: 700;
        color: rgb(255, 106, 64);
        margin-top: 50px;
      }
      .chart {
        margin-top: 10px;
        height: 350px;
        background: rgb(0, 118, 174);
      }
      .chartTable {
        margin-top: 20px;
        table {
          width: 80%;
          margin: 0px auto;
          border-collapse: collapse;
          border-top: 1px solid black;
          border-left: 1px solid black;
          tr {
            th,
            td {
              border-right: 1px solid black;
              border-bottom: 1px solid black;
              padding: 5px 0;
            }
            td {
              text-align: center;
              &:nth-child(1) {
                font-weight: 700;
              }
            }
          }
        }
      }
      //描述
      .describe {
        padding: 10px 0;
      }
      .home-title {
        height: 450px;
        box-sizing: border-box;
        border: 5px solid rgb(35, 151, 188);
        text-align: center;
        p {
          font-weight: 700;
          &:nth-child(1) {
            font-size: 28px;
            margin-top: 60px;
          }
          &:nth-child(2) {
            margin-top: 10px;
          }
          &:nth-child(3) {
            margin-top: 180px;
          }
          &:nth-child(4) {
            margin-top: 10px;
          }
        }
      }
      .statement {
        margin-top: 50px;
        .statement-title {
          margin-bottom: 10px;
          p {
            text-align: center;
            font-weight: 700;
            font-size: 20px;
          }
        }
      }
      .situation {
      }
      .analysis {
        .divisor {
          margin-top: 20px;
          .divisor-item {
            padding: 12px;
          }
        }
      }
      .summarize {
        border-bottom: 2px solid #aaaaaa;
        .summarize-conten {
          padding: 20px 0;
        }
      }
      .msg {
        padding: 30px 0;
        p {
          font-weight: 700;
          text-align: center;
        }
      }
      .signature {
        margin-top: 50px;
        font-size: 18px;
        float: right;
        span {
          &:nth-child(1) {
            width: 100px;
            display: inline-block;
            border-bottom: 1px black solid;
            margin-right: 30px;
          }
        }
      }
    }
    // 简易
    .simple {
      .header-title {
        text-align: center;
        font-size: 28px;
        .hosName {
        }
        .measName {
          padding-top: 10px;
          font-weight: 700;
        }
      }
      .userInfo {
        margin-top: 50px;
        font-size: 18px;
        p {
          margin-bottom: 35px;
        }
        .unitName {
          font-weight: 700;
        }
        .date {
        }
      }
      .statistics {
        margin-top: 70px;
        .statistics-title {
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 20px;
        }
        .statistics-table-name {
          font-size: 18px;
        }
        .statistics-table {
          margin-top: 10px;
          table {
            width: 100%;
            border: 1px solid black;
            tr {
              td,
              th {
                width: 7.04%;
                text-align: center;
                padding: 7px 0;
                border-bottom: 1px solid black;
                border-right: 1px solid black;
                &:last-child {
                  border-right: 0px;
                }
              }
              th {
                &:first-child {
                  width: 18.18%;
                }
              }
              &:last-child {
                td,
                th {
                  border-bottom: 0px;
                }
              }
              // &:last-child {
              //   td {
              //     &:first-child {
              //       font-weight: 700;
              //     }
              //   }
              // }
            }
          }
        }
      }
      .classify {
        margin-top: 70px;
        .classify-title {
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 20px;
        }
        .classify-table {
          margin-top: 10px;
          table {
            width: 100%;
            border: 1px solid black;
            tr {
              td,
              th {
                width: 12.5%;
                text-align: center;
                padding: 10px 0;
                border-bottom: 1px solid black;
                border-right: 1px solid black;
                &:last-child {
                  border-right: 0px;
                }
              }
              th {
                &:last-child {
                  width: 50%;
                }
              }
              &:last-child {
                td,
                th {
                  border-bottom: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>